<template>
  <li
    class="course-item"
    @click="navigateToDetail()"
    :style="{ background: index % 2 == 1 ? '#6E76FF' : '#ffffff' }"
  >
    <img class="logo" src="@/assets/smalllogo.png" alt="" srcset="" />
    <img class="rangc" src="@/assets/ran.png" alt="" srcset="" />
    <img class="icon" :src="item.icon" alt="" srcset="" />
    <div
      class="course-name"
      :style="{ color: index % 2 == 0 ? '#2A2A31' : '#ffffff' }"
    >
      {{ item.Type }}
    </div>
    <div
      class="number-warp"
      :style="{ color: index % 2 == 0 ? '#2A2A31' : '#ffffff' }"
    >
      <span class="number">{{ item.TeachTime }}</span>
      <span class="unit">课时</span>
    </div>
    <div
      v-if="showDown"
      class="number-warp download"
      :style="{ color: index % 2 == 0 ? '#2A2A31' : '#ffffff' }"
    >
      <span class="number">{{ item.DownCount }}</span>
      <span class="unit">下载</span>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          name: "",
          Type: "",
          TeachTime: "",
          DownCount: "",
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showDown: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    navigateToDetail() {
      this.$emit("navigateToDetail", this.item);
    },
  },
};
</script>

<style lang="less">
.course-item {
  position: relative;
  width: 300px;
  height: 183px;
  margin-right: 36px;
  margin-bottom: 36px;
  border-radius: 28px;
  overflow: hidden;
  cursor: pointer;
  background: white;
  .logo {
    position: absolute;
    top: 0;
    right: 60px;
    width: 98px;
    height: 65px;
  }
  .rangc {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 146px;
    height: 127px;
  }
  .icon {
    position: absolute;
    width: 146px;
    height: 134px;
    right: 18px;
    bottom: 18px;
  }
  .course-name {
    position: absolute;
    z-index: 2;
    left: 24px;
    top: 22px;
    font-size: 18px;
    font-family: SourceHanSansCN-Medium;
    line-height: 27px;
    color: #2a2a31;
  }
  .number-warp {
    position: absolute;
    left: 24px;
    top: 60px;
    z-index: 3;
    .number {
      font-size: 30px;
      font-family: SourceHanSansCN-Medium;
    }
    .unit {
      font-size: 15px;
      font-family: SourceHanSansCN-Medium;
    }
  }
  .download {
    top: 113px;
  }
}
</style>